<template>
  <!-- 个人设置菜单页 -->
  <div class="profile_setting">
    <!-- 左侧面板 -->
    <el-card class="card_left">
      <el-tree
        class="tree"
        :data="data"
        :props="defaultProps"
        :filter-node-method="filterNode"
        ref="tree"
        highlight-current
        node-key="id"
        :default-expanded-keys="default_expanded_keys"
        :current-node-key="current_node_key"
        :render-after-expand="false"
        @current-change="currentChange"
      >
      </el-tree>
    </el-card>
    <!-- 右侧 -->
    <div class="box_right">
      <!-- 顶部面板顶部 -->
      <el-card class="card_right_top">
        <div class="left_box">
          <svg
            class="svgimg"
            aria-hidden="true"
            height="40"
            width="40"
            viewBox="0,0,40,40"
          >
            <use href="#icon-profile_setting_icon"></use>
          </svg>
        </div>
        <div class="right_box">
          <!-- 设置 -->
          <div class="right_box_min_title">
            {{ $t("label.emailsync.form.seversetting") }}
          </div>
          <!-- 个人设置 -->
          <div class="right_box_big_title">
            {{ $t("label.personalsetting") }}
          </div>
        </div>
      </el-card>
      <!-- 底部面板路由 -->
      <router-view />
    </div>
  </div>
</template>

<script>
/**
 * 个人设置
 */
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    // 菜单筛选
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 菜单切换
    currentChange(current) {
      let { id, label, path } = current;
      this.current_node_key = id;
      this.current_node_label = label;
      this.current_node_path = path;
      if (path) {
        // 如果是跳转到个人邮箱设置，需要携带参数
        if (path === "personalEmailBox") {
          let userInfo = this.$store.state.userInfoObj;
          path = `personalEmailBox?userid=${userInfo.userId}&groupid=${userInfo.orgId}&lange=${userInfo.language}&countryCode=${userInfo.countryCode}&timezone=${userInfo.timeZone}`;
        }
        this.$router.push(`/profileSetting/${path}`);
      }
    },
  },
  data() {
    return {
      filterText: "", //筛选字段
      default_expanded_keys: [1], //默认展开节点
      current_node_key: 11, //当前选中节点
      current_node_label: this.$i18n.t("label.pc.ui.2018.personalinfo"), //当前选中节点文本（非自带）  个人信息
      current_node_path: "profileInfo", //当前选中节点路由（非自带）
      data: [
        {
          id: 1,
          label: this.$i18n.t("label.personalinfo"), // 我的个人信息
          children: [
            {
              id: 11,
              label: this.$i18n.t("label.pc.ui.2018.personalinfo"), // 个人信息
              path: "profileInfo",
            },
            {
              id: 22,
              label: this.$i18n.t("label.userinfo.updatepwd"), // 更改密码
              path: "modifyPassword",
            },
            {
              id: 33,
              label: this.$i18n.t("label.login.access.title"), // 授予客户访问权限
              path: "authVisit",
            },
            {
              id: 44,
              label: this.$i18n.t("label.loginhistory"), // 登录历史
              path: "loginHistory",
            },
          ],
        },
        // {
        //   id: 2,
        //   label: this.$i18n.t('label.emailsync.setting'),  // 个人邮箱设置
        //   children: [
        //     {
        //       id: 5,
        //       label: this.$i18n.t('label.emailsync.connectaccount'),  // 关联邮箱账号
        //       path: "personalEmailBox",
        //     },
        //   ],
        // },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  created() {
    // 私有云不支持邮箱功能
    if (localStorage.getItem("private") === "false") {
      this.data.push({
        id: 2,
        label: this.$i18n.t("label.emailsync.setting"), // 个人邮箱设置
        children: [
          {
            id: 5,
            label: this.$i18n.t("label.emailsync.connectaccount"), // 关联邮箱账号
            path: "personalEmailBox",
          },
        ],
      });
    }
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
  },
};
</script>

<style  lang="scss" scoped>
.profile_setting {
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  .card_left {
    flex-shrink: 0;
  }
  ::v-deep .card_left .el-card__body {
    width: 250px;
    padding: 20px 0;
  }
  .tree {
    width: 250px;
    height: 100%;
  }
  ::v-deep 
    .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: #e3f0f9;
  }
  ::v-deep .el-tree-node__content {
    height: 30px;
  }

  ::v-deep 
    .el-tree
    > .el-tree-node
    > .el-tree-node__content
    .el-tree-node__label {
    font-size: 16px !important;
    font-weight: bold;
  }
  //   右侧样式
  .box_right {
    flex: 1;
    margin: 0 10px;
    //   右侧面板顶部
    .card_right_top {
      height: 60px;
      ::v-deep .el-card__body {
        display: flex;
        padding: 10px;
        .right_box {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .right_box_min_title {
            font-size: 12px;
            color: #006dcc;
          }
          .right_box_big_title {
            font-size: 16px;
            color: #080707;
            letter-spacing: 0;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.svgimg {
  display: inline-block;
}
</style>